// src/plugins/i18n.js

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '../locales/en.json'
import fr from '../locales/fr.json'
const messages = {
  en,
  fr
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  messages
})

export default i18n
