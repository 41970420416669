<template>
  <div id="app" class="inputkit-widget-container">
    <v-app>
      <v-main>
        <template v-if="loading">
          <div class="text-center">
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
          </div>
        </template>
        <template v-else-if="reviews && reviews.length > 0">
          <div>
            <div>
              <v-slide-group
                :show-arrows="$vuetify.breakpoint.mdAndUp"
                v-model="selectedReview"
              >
                <v-slide-item
                  v-slot="{ active, toggle }"
                  v-for="review in reviews"
                  :key="review.id"
                >
                  <v-card
                    class="ma-2 widget-review-item elevation-3"
                    @click="toggle"
                  >
                    <v-card-text class="pa-0">
                      <div class="pa-3">
                        <div class="pt-3 pb-1">
                          <v-layout>
                            <v-img
                              contain
                              :src="review.customer.photo"
                              max-width="60px"
                              height="auto"
                              :alt="review.customer.name"
                            >
                            </v-img>
                            <div class="my-auto ml-3 widget-rating-container">
                              <div class="ml-1">
                                <img
                                  alt="Google"
                                  class="source-icon"
                                  src="https://ucarecdn.com/300052c3-f35c-4d21-9f7e-5b92f26df3b9/google.png"
                                />
                              </div>
                              <v-rating
                                v-if="review.rating"
                                v-model="review.rating"
                                dense
                                size="20"
                                readonly
                                background-color="grey lighten-1"
                                empty-icon="$vuetify.icons.ratingFull"
                                class="google-reviews-color"
                              >
                              </v-rating>
                              <div>
                                <v-layout>
                                  <v-flex>
                                    <v-layout wrap>
                                      <span
                                        class="widget-customer-name mr-2 ml-1"
                                      >
                                        <strong>{{
                                          review.customer.name
                                        }}</strong>
                                      </span>
                                      <span
                                        class="grey--text darken-3 font-italic widget-review-date"
                                      >
                                        {{ review.date_created | fromNow }}
                                      </span>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </div>
                          </v-layout>
                        </div>
                        <div
                          v-if="review.shortComment"
                          class="grey lighten-4 pa-2 item-comment-container"
                        >
                          <div class="item-comment">
                            <span
                              class="widget-review-text"
                              v-if="!review.showMore"
                              >{{ review.shortComment }}</span
                            >
                            <span class="widget-review-text" v-else>{{
                              review.comment
                            }}</span>
                            <v-btn
                              small
                              v-if="review.commentIsLong"
                              icon
                              @click="review.showMore = !review.showMore"
                            >
                              <v-icon>{{
                                review.showMore
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </div>
            <div class="text-center mt-4 widget-powered-by" v-if="!hide">
              <a
                style="text-decoration: none; color:#444;"
                :href="urlLink"
                target="_blank"
              >
                <img
                  class="powered-by-logo-large-img"
                  src="https://d3d51htco0t6v3.cloudfront.net/img/powered-by-inputkit.png"
                  v-if="language === 'en'"
                />
                <img
                  class="powered-by-logo-large-img"
                  src="https://d3d51htco0t6v3.cloudfront.net/img/propulse-par-inputkit.png"
                  v-else
                />
              </a>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- No reviews -->
        </template>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  name: 'reviewsWidget',
  props: ['publicId', 'onlyFiveStars', 'onlyWithComment', 'language', 'locationId', 'hide'],
  data () {
    return {
      selectedReview: null,
      reviews: [],
      loading: false
    }
  },
  created () {
    this.activeTab = this.$route.name
    Vue.i18n.locale = this.language
  },
  mounted () {
    console.log('InputKit reviews widget - Starting')
    this.getData()
  },
  methods: {
    async getData () {
      console.log('InputKit reviews widget v1.5 - Getting recent reviews')
      const KEY = this.publicId;
      const onlyFiveStars = this.onlyFiveStars
      const onlyWithComment = this.onlyWithComment
      const locationId = this.locationId ? this.locationId : null
      const locale = this.language
      this.loading = true
      try {
        const response = await Vue.axios.post(`api/companies/${KEY}/reviews`, {
        onlyFiveStars,
        onlyWithComment,
        locale,
        locationId: locationId
      }, {
        timeout: 2000 // IN-4515 add timeout
      })
        if (response && response.data && response.data.reviews) {
          console.log('InputKit reviews widget - Nb Reviews:', response.data.reviews.length)
          this.reviews = response.data.reviews
        } else {
          console.log('InputKit reviews widget - Found 0 review :', response)
          this.reviews = []
        }
      }
      catch(error) {
        console.log('InputKit reviews widget - Error :', error)
        this.reviews = []
      } finally {
        this.loading = false
      };
    },
    switchTab (tab) {
      this.$router.push({ name: tab })
    }
  },
  computed: {
    urlLink () {
      if (this.language === 'en') {
        return 'https://inputkit.io/en/?utm_campaign=reviews-widget&utm_source=inputkit-client&utm_medium=reviews-widget'
      } else {
        return 'https://inputkit.io/fr/?utm_campaign=reviews-widget&utm_source=inputkit-client&utm_medium=reviews-widget'
      }
    }
  },
  filters: {
    fromNow (date) {
      return moment(date).locale(Vue.i18n.locale).local().fromNow()
    }
  },
  watch: {
    '$route.name' () {
      this.activeTab = this.$route.name
    }
  }
}
</script>
<style lang="scss">
#app {
  background: transparent;
}

.inputkit-widget-container {
  background: transparent;

  .v-application--wrap,
  .v-main,
  .v-main__wrap {
    height: fit-content;
    flex-grow: 0 !important;
    min-height: 0 !important;
  }

  .widget-rating-container {
    text-align: left !important;

    .google-reviews-color button {
      color: #e7711b !important;
      caret-color: #e7711b !important;
    }

    .source-icon {
      height: 20px;
      width: auto;
    }
  }

  .widget-customer-name {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .widget-review-date {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .widget-review-text {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .widget-powered-by {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .item-comment-container {
    text-align: left !important;
  }

  .google-reviews-color {
    color: #e7711b;
  }

  .widget-review-item {
    border-radius: 3px;
    // height: fit-content;
    /*min-height: 240px;*/
    max-width: 350px;
    min-width: 350px;

    .item-comment-container .item-comment {
      border-radius: 5px;
    }
  }

  .powered-by-logo-large-img {
    height: 50px !important;
    width: auto !important;
    vertical-align: middle !important;
  }
}

@media screen and (max-width: 640px) {
  .widget-review-item {
    min-width: 220px !important;
    max-width: 250px !important;
  }
}

#app,
.v-application--wrap,
.v-main,
.v-main__wrap {
  width: 100%;
}
</style>
<style src="../node_modules/vuetify/dist/vuetify.min.css"></style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css");

$body-font-family: "Lato";
$heading-font-family: "Lato";
.v-application {
  font-family: $body-font-family, sans-serif !important;

  body,
  *,
  .body-2 {
    font-family: $body-font-family, sans-serif !important;
  }
}
</style>
