// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  // icons: {
  //   iconfont: 'mdi', // default - only for display purposes
  // },
  theme: {
    dark: false,
    shadowMode: true,
  }
};

export default new Vuetify(opts)
