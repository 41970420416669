import Vue from 'vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import App from './App.vue'
import router from './router'
import ReviewsWidget from './App'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from '@/plugins/i18n'

import 'vuetify/dist/vuetify.css'
import '@mdi/font/css/materialdesignicons.min.css'

// widget setup
// (optional) 'Custom elements polyfill'
import 'document-register-element/build/document-register-element'
// vue-custom-element by karol-f
import vueCustomElement from 'vue-custom-element'

const URL = 'https://backend-web.inputkit.io';
// const URL = 'http://localhost:5000';

const instance = axios.create({
  baseURL: URL
})

Vue.use(VueAxios, instance)

Vue.use(vuetify)
Vue.use(vueCustomElement)
Vue.config.productionTip = false

// use vue-custom-element
App.router = router

ReviewsWidget.vuetify = vuetify;
ReviewsWidget.i18n = i18n
Vue.i18n = i18n

Vue.customElement('reviews-widget', ReviewsWidget, {
  shadow: true,
  beforeCreateVueInstance (root) {
    const rootNode = root.el.getRootNode();

    if (rootNode instanceof ShadowRoot) {
      root.shadowRoot = rootNode;
    } else {
      root.shadowRoot = document.head;
    }
    return root;
  },
});
